import React, { useEffect, useState } from "react";
import {
  getDrivers,
  updateDriver,
  deactivateDriver,
} from "../../../api/apiService";
import { updateDriverValidation } from "../../../provider/validationProvider";
import { ToastContainer, toast, Bounce } from "react-toastify";

const EditDriver = ({ refreshKey }) => {
  const [driverContactNumber, setDriverContactNumber] =
    useState("Contact Number");
  const [driverName, setDriverName] = useState("Driver Name");
  // Update Values
  const [newDriverName, setNewDriverName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [userId, setUserId] = useState("");
  const [driverList, setDriverList] = useState([]);

  // Select Driver
  /**This function is disabled due to chnge of the driver selection from typing numbers to select from list */
  //eslint-disable-next-line
  const selectDriver = async (e) => {
    e.preventDefault();
    try {
      const data = await getDrivers();
      const drivers = data.driverInfo;

      // Check is driver available in the database
      const isContactNumberExist = (driverContactNumber, drivers) => {
        if (
          drivers.some((driver) => driver.ContactNo === driverContactNumber)
        ) {
          return drivers.some(
            (driver) => driver.ContactNo === driverContactNumber
          );
        } else {
          toast.error("Driver Not Found", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      };

      if (isContactNumberExist(driverContactNumber, drivers)) {
        const driver = drivers.find(
          (driver) => driver.ContactNo === driverContactNumber
        );
        setDriverName(driver.DriverName);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Refetch driver list when refreshKey changes
    fetchDrivers();
  }, [refreshKey]);

  // Change Driver Infomation
  const changeDriverInfo = async (e) => {
    e.preventDefault();

    try {
      // if there is a new password then only it checks password is equals to the confirm password. Otherwise it will not change the driver information.
      if (newPassword) {
          updateDriverValidation(newDriverName, newPassword);
          const response = await updateDriver(
            userId,
            newDriverName,
            driverContactNumber,
            newPassword
          );
          if (response.data.success === true) {
            toast.success("Driver information changed successfully.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
          
              theme: "light",
            
            });
            setDriverContactNumber("");
            setDriverName("");
            setNewPassword("");
          }else{
            toast.error("Error Change Information.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          }
        
      } else {
        toast.warning(
          "Please enter password to change information.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          }
        );
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const removeDriver = async () => {
    try {
      if (userId !== "") {
        const response = await deactivateDriver(userId);
        if (response.success === true) {
          toast.success("Driver Removed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      } else {
        console.log("Driver Cannot be found");
      }
    } catch (err) {
      console.error(err);
    }
  };

  /**get driver list funtion
   * this funtion is responsible for get driver list.
   * called to middleware funtion - getDrivers
   * No pareameters needed for this API
   * create it inside the useEffect hook in order to render at initial component rendering
   */
  useEffect(() => {
    /**This function will call inside the useEffect hook. */
    fetchDrivers();
  }, []);

  const fetchDrivers = async () => {
    try {
      const response = await getDrivers();
      if (response.success === true) {
        if (response.driverInfo.length > 0) {
          setDriverList(response.driverInfo);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * select driver by using driver list.
   * After selecting the driver this function will set state variables.
   */
  const selectDriverFromList = (contactNo, driverName, userId) => {
    if (contactNo && driverName && userId) {
      setDriverContactNumber(contactNo);
      setDriverName(driverName);
      setUserId(userId);
    }
  };

  return (
    <>
      <div className="edit__driver__container">
        <h3 className="heading-2">EDIT / REMOVE DRIVER INFORMATION</h3>

        <div className="ed-sub-container">
          {/* Selecting the driver */}
          <div className="select-driver-container">
            {driverList.map((driver, index) => (
              <button
                key={index}
                className="heading-3"
                onClick={() => {
                  selectDriverFromList(
                    driver.ContactNo,
                    driver.DriverName,
                    driver.UserID
                  );
                }}
              >
                {driver.DriverName}
              </button>
            ))}
          </div>

          <form action="" className="edit__driver__details">
            <h5 className="edit__driver__selection heading-3">
              Select Driver by Contact Number
            </h5>

            {/* Update Driver Information */}
            {/* Driver Name */}
            <p className="e__dr__driver__name heading-3">Driver Name</p>
            <input
              type="text"
              className="input"
            
              placeholder={
                driverName ? `${driverName}` : "Enter Phone Number Find Driver"
              }
              onChange={(e) => setNewDriverName(e.target.value)}
            />
            {/* Driver Contact Number */}
            <p className="e__dr__contact__number heading-3">Contact Number</p>
            <input
              type="text "
              className="input"
         
              placeholder={
                driverContactNumber
                  ? `${driverContactNumber}`
                  : "Enter Phone Number to Find Driver"
              }
             
            />
            <p className="heading-3">Password</p>
            <input
              type="password"
              className="input"
  
              onChange={(e) => setNewPassword(e.target.value)}
            />
            
            <div className="edit-driver-information-buttons">
              <button
                className="button button-3 e__dr__btn__remove__driver"
                onClick={removeDriver}
              >
                Remove Driver
              </button>
              <button
                className="button button-2 e__dr__btn__change__info"
                onClick={changeDriverInfo}
              >
                Change Information
              </button>
            </div>
          </form>
        </div>
        <ToastContainer />
      </div>

      {/* Change Driver Information mobile */}
      <div className="edit__driver__mobile__container">
        <h2 className="heading-2">Edit/Remove Driver Information</h2>
        <form action="" className="ed__dr__form__mob">
          <p className="text">Select Driver Using Driver's Contact Number</p>
          <input type="text" className="input" placeholder="Contact Number" />
          <div className="ed__dr__form__mob__select">
            <button className="button button-3" onClick={removeDriver}>
              Remove Driver
            </button>
          </div>
          <input type="text" className="input" placeholder="Driver Name" />
          <input type="text" className="input" placeholder="Contact Number" />
          <input type="password" className="input" placeholder="Old Password" />
          <input type="password" className="input" placeholder="New Password" />
          <input
            type="password"
            className="input"
            placeholder="Confirm Password"
          />
          <button className="button button-2">Change Information</button>
        </form>
        <ToastContainer />
      </div>
    </>
  );
};

export default EditDriver;
