import axios from "axios";
import API_URL from "./apiCommon"

const getAccessToken = async () => {
  try {
    const response = await axios.get(
      `${API_URL}v1.0/access/microsooft/API`
    );
    return response.data;
  } catch (err) {
    console.error("Error calling microsoft authentication endpoint: ", err);
  }
};

const uploadImage = async (path, fileContent, access_token) => {
  try {
    const response = await axios.put(
      `https://graph.microsoft.com/v1.0/drive/root:/${path}:/content`,
      fileContent,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/octet-stream",
        },
      }
    );
    return response;
  } catch (err) {
    console.error("Upload error:", {
      status: err.response?.status,
      statusText: err.response?.statusText,
      data: err.response?.data,
      headers: err.response?.headers,
    });

  }
};

export { getAccessToken, uploadImage };


