import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
// import Typography from "@mui/material/Typography";
// import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";


const GOOGLE_MAPS_API_KEY = "AIzaSyDTvdnMyRYCq8L7-gjlZgns_dn3B3KDJ7g";

function loadScript(src, position, id, callback) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  script.onload = callback;
  position.appendChild(script);
}


const autocompleteService = { current: null };

export default function GoogleMaps({ onPlaceSelect, clearInput }) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps",
      );
    } 

    loaded.current = true;
  }

//   /**
//    * resricting autocomplete location to australia.
//    * use places autocomple restrictions.
//    * reference google maps developer documentation.
//    * https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-multiple-countries#maps_places_autocomplete_multiple_countries-javascript
//    */
//  function initAutocomplete() {
//     const input = document.getElementById("autocomplete");
//     const options = {
//       componentRestrictions: { country: 'AU' } // Restricting to Australia
//     };

//     const autocomplete = new window.google.maps.places.Autocomplete(input, options);

//     autocomplete.addListener('place_changed', () => {
//       const place = autocomplete.getPlace();
//       onPlaceSelect(place);
//     });
//     autocompleteService.current = autocomplete
//   }
  // if(dropAddedState === false){
  //   setInputValue("");
  //   setValue("");
  // }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;


    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: 300, fontFamily: "comfortaa" }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(options, params) => {
        const filtered = options.filter((option) =>
          option.description
            ?.toLowerCase()
            .includes(params.inputValue.toLowerCase())
        );

        if (filtered.length === 0 && params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            description: `Set This Location "${params.inputValue}"`,
            custom: true,
          });
        }

        return filtered;
      }}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="Set This Location"
      onChange={(event, newValue) => {
        if (newValue && newValue.custom) {
          const customLocation = { description: newValue.inputValue };
          setValue(customLocation);
          onPlaceSelect(customLocation);
        } else {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          if (newValue) {
            onPlaceSelect(newValue);
          }
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Add a location"
          sx={{
            fontFamily: "Comfortaa",
            "& .MuiInputBase-input": {
              fontFamily: "comfortaa",
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item sx={{ display: "flex", width: 50 }}>
              <LocationOnIcon sx={{ color: "text.secondary" }} />
            </Grid>
            <Grid
              item
              sx={{
                width: "calc(100% - 44px)",
                wordWrap: "break-word",
                fontFamily: "Comfortaa",
                fontStyle: "bold",
              }}
            >
              <Box
                component="span"
                sx={{
                  fontWeight: option.custom ? "bold" : "regular",
                  fontFamily: "Comfortaa",
                }}
              >
                {option.description}
              </Box>
            </Grid>
          </Grid>
        </li>
      )}
    />
  );
}
