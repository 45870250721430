import {useState} from "react";
import Sidepanel from "../../components/administrator/common/sidepanel";
import DriverRegistration from '../../components/administrator/driverManagement/driverRegistration'
import EditDriver from "../../components/administrator/driverManagement/editDriver";
import BottomPanel from "../../components/administrator/common/bottomPanel";
import { ToastContainer } from "react-toastify";

const DriverManagement = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const handleRefresh = () => {
    setRefreshKey((prev) => prev + 1); // Increment to force refresh
  };
  return (
    <>
      <div className="driver__management__container">
        <Sidepanel />
        <div className="driver__management__content">
          <h1 className="title">DRIVER MANAGEMENT</h1>
          <DriverRegistration  onSuccess={handleRefresh}  />
          <EditDriver refreshKey={refreshKey}/>
          <ToastContainer  className="notifications" />
        </div>
        <BottomPanel />
      </div>
    </>
  );
};

export default DriverManagement;
