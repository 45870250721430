import api from './api';

// Driver Registration
const registerDriver = async (driverName, contactNo, password) => {
  try {
    const response = await api.post(
      "v1.0/access/new/driver",
      {
        driver_name: driverName,
        contact_no: contactNo,
        password: password,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error registering Driver" + error);
    // window.location.href = "/";
  }
};

const getDrivers = async () => {
  try {
    const response = await api.get(
      "v1.0/access/driver/list"
    );
    return response.data;
  } catch (error) {
    console.error("Error updating Driver" + error);
    // window.location.href = "/";
  }
};

const getDriver = async (contactNo) => {
  try {
    const response = await api.post(
      "v1.0/access/driver/info",
      {
        contact_no: contactNo,
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    // window.location.href = "/";
  }
};

const deactivateDriver = async (driverId) => {
  try {
    const response = await api.post(
      "v1.0/access/deactivate/driver",
      {
        user_id: driverId,
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    // window.location.href = "/";
  }
};

const updateDriver = async (userId, driverName, contactNo, password) => {
  try {
    const response = await api.post(
      "v1.0/access/update/driver",
      {
        user_id:userId,
        driver_name: driverName,
        contact_no: contactNo,
        password: password,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating Driver" + error);
    // window.location.href = "/";
  }
};

const getDrops = async () => {
  try {
    const response = await api.get(
      "v1.0/access/drop/details"
    );
    return response.data;
  } catch (err) {
    console.error(err);
    // window.location.href = "/";
  }
};

const postMessage = async (userId, driverId, message, images) => {
  try {
    const response = await api.post(
      "v1.0/access/new/admin-messages",
      {
        userid: userId,
        driver_id: driverId,
        message: message,
        image_list: images,
      },
    );
    return response.data;
  } catch (err) {
    console.error(err);
    // window.location.href = "/";
  }
};

// Post Broacat messaging

const broadcastMessage = async (userId, message) => {
  try {
    const response = await api.post(
      "v1.0/access/new/admin-broadcast",
      {
        userid: userId,
        message: message,
        image_list: [],
      },
    );
    return response.data;

  } catch (err) {
    console.error(err);
  }
};

const getDriverMessages = async (driverId) => {
  try {
    const response = await api.post(
      "v1.0/access/chat/messages",
      {
        driver_id: driverId
      },
    );
    return response.data;
  } catch (err) {
    console.error(err);
    // window.location.href = "/";
  }
};

const getSpecificDropData = async (dropId) => {
  try {
    const response = await api.post(
      "v1.0/access/drop/information",
      {
        drop_id: dropId
      },
    );
    return response.data;

  } catch (err) {
    console.error(err);
    window.location.href = "/";
  }
};

const sendHelpRequest = async (userId, email, subject, message) => {
  try {
    const response = await api.post(
      "v1.0/access/help/desk",
      {
        userid: userId,
        email: email,
        subject: subject,
        body: message,
      },
    );
    return response.data;

  } catch (err) {
    console.error(err);
    // window.location.href = "/";
  }
};

export {
  registerDriver,
  getDrivers,
  updateDriver,
  getDriver,
  deactivateDriver,
  getDrops,
  postMessage,
  getDriverMessages,
  getSpecificDropData,
  sendHelpRequest,
  broadcastMessage,
};
