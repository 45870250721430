import React, { useEffect, useState } from "react";
import "../../../styles/driver.css";
import { useParams } from "react-router-dom";
import { deliveryComplete } from "../../../api/apiDriver";
import { ToastContainer, toast, Bounce } from "react-toastify";
import { IoMdAttach } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { getSpecificDropData } from "../../../api/apiService";
import { getAccessToken, uploadImage } from "../../../api/OneDrive";

const IndividualDrop = () => {
  // const driverId = localStorage.getItem("userID");
  const { id } = useParams();
  const [dropData, setDropData] = useState([]);
  const [images, setImages] = useState([]);
  const [dropName, setDropName] = useState("");
  const [dropLocation, setDropLocation] = useState("");
  const [dropMessage, setDropMessage] = useState("");
  const [dropStatus, setDropStatus] = useState("");
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [proofImagePath, setProofImagePath] = useState([]);
  const [showProofImages, setShowProofImages] = useState([]);
  const [fileCount, setFileCount] = useState(1);

  // flags
  const [isImageAttached, setIsImageAttached] = useState(false);
  const [dropCompleteAvailable, setDropCompleteAvailable] = useState(false);

  //navigate to back
  const handleBackNavigate = () => {
    navigate("/driver");
  };

  // open camera module
  // eslint-disable-next-line
  const openCamera = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        source: CameraSource.Camera, // Opens the camera directly
      });
      addImages(image.base64String);
      // You can use image.webPath to display or upload the photo
    } catch (error) {
      console.error("Error taking photo:", error);
    }
  };

  // eslint-disable-next-line
  const openGallery = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        source: CameraSource.Photos, // Opens the gallery directly
      });
      addImages(image.base64String);

      // You can use image.webPath to display or upload the photo
    } catch (error) {
      console.error("Error selecting photo:");
    }
  };

  useEffect(() => {
    // drop data fetch
    const getDropData = async () => {
      const data = await getSpecificDropData(id);
      console.log(data)

      if (data != null) {
        try {
          setDropData(data.dropInfo[0]);
          setDropName(data.dropInfo[0].DropName);
          setDropLocation(data.dropInfo[0].Location);
          setDropStatus(data.dropInfo[0].DropStatus);

          if (data.dropInfo[0].DropStatus === "Done") {
            // set proof images array
            setShowProofImages(data.dropInfo[0].ProofImagePath);
            console.error("setted drop proof image");
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        console.error("Data not avaialble");
      }
    };

    // access token request
    const getGraphAPIAccessToken = async () => {
      try {
        const access_token = await getAccessToken();
        if (!access_token) {
          console.error("Didn't recieved access token");
        } else {
          setAccessToken(access_token.response.access_token);
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (isMounted === false) {
      getDropData();
      getGraphAPIAccessToken();
      setIsMounted(true);
    }
  }, [id, isMounted]);

  // Convert timestamp to data and time
  const formatDate = (timestamp) => {
    if (timestamp !== null && timestamp !== undefined) {
      const date = new Date(timestamp * 1000);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return date.toLocaleString("en-US", options);
    } else {
      return "";
    }
  };

  const formatTime = (timestamp) => {
    if (timestamp !== null && timestamp !== undefined) {
      const time = new Date(timestamp * 1000);
      const options = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return time.toLocaleString("en-US", options);
    } else {
      return "";
    }
  };

  // add image to UI and base64 array
  const addImages = async (e) => {
    try {
    } catch (err) {
      console.error(err);
    }
  };

  // Open in Google maps
  const openInGoogleMaps = () => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${dropLocation}`
    );
  };

  // drop confirm image delete
  const handleDeleteImage = (indexToRemove) => {
    const updatedImages = images.filter((_, index) => index !== indexToRemove);
    setImages(updatedImages);
    if (fileCount > 0) {
      setFileCount(fileCount - 1);
    }
  };

  // image handler
  const handleImageChange = async (e) => {
    const uploading = toast.loading("Uploading...");
    setIsImageAttached(true);
    if (!e) {
      console.error("No attached images");
    } else {
      const file = e.target.files[0];
      if (file) {
        // upload event - making data
        const filePath = `Greenlands/DropImages/${id}/${id}-${fileCount}.${file.name
          .split(".")
          .pop()}`;
        // add items to previous array
        setImages((prevImages) => [...prevImages, URL.createObjectURL(file)]);
        // Increase File Count
        setFileCount(fileCount + 1);

        if (file && accessToken && filePath) {
          try {
            const response = await uploadImage(filePath, file, accessToken);
            if (response.status === 200 || response.status === 201) {
              setProofImagePath((prevPaths) => [
                ...prevPaths,
                { imagePath: response.data.webUrl },
              ]);
              console.log(response);
              // toast success message
              toast.update(uploading, {
                render: "Upload Completed. You can Complete the Drop",
                type: "success",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
              // enalbe drop complete button
              setDropCompleteAvailable(true);
            } else {
              console.error("file not saved in sharepoint");
              toast.update(uploading, {
                render: "Image not uploaded. Please Try again",
                type: "warning",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
            }
          } catch (err) {
            console.error(err);
          }
        } else {
          console.error("Image not uploaded");
        }
      } else {
        console.error("File is not available");
      }
    }
  };

  // complete drop
  const completeDrop = async () => {
    const dropCompleting = toast.loading("Drop Completing...");
    try {
      const response = await deliveryComplete(id, dropMessage, proofImagePath);
      setTimeout(() => {
        toast.update(dropCompleting, {
          render: "Drop is not completed. Please Try Again",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }, 10000);
      setTimeout(() => {
        navigate("/driver");
      }, 12500);
      if (response.success) {
        toast.update(dropCompleting, {
          render: "Drop Completed",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        navigate("/driver");
      } else {
        toast.update(dropCompleting, {
          render: "Drop Completion Error",
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="individual__drop__container">
        <div className="drop-container-heading">
          <ArrowBackIosRoundedIcon onClick={handleBackNavigate} />
          <h1 className="title idc-title">DASHBOARD</h1>
        </div>

        <div className="individual__drop__time">
          <div className=" individual-drop-data">
            <div>
              <h2 className="heading-1">{dropName}</h2>
              <p
                className={`text individual-drop-state ${
                  dropData.DropStatus === "Done"
                    ? "individual-drop-state-completed"
                    : "individual-drop-state-inprogress"
                }`}
              >
                {dropData.DropStatus === "InProgress"
                  ? "In Progress"
                  : dropStatus}
              </p>
            </div>

            <h2 className="heading-3">{formatDate(dropData.DateTime)} </h2>

            <h2 className="heading-3">{formatTime(dropData.DateTime)}</h2>
            <p className="heading-3">{dropData.Location}</p>
          </div>
        </div>
        <div className="individual__drop__address"></div>

        <button className="button button-1" onClick={openInGoogleMaps}>
          Open in Google Maps
        </button>
        {/* if drop status is InProgress will show user adding image array if not wil show image that request from backend */}
        <div className="individual__drop__image__container">
          {dropStatus === "InProgress" && isImageAttached === true ? (
            <div className="image-container">
              {images.map((image, index) => (
                <div>
                  <img
                    key={index}
                    id="inprogress-image"
                    src={image}
                    alt={`Preview ${dropName}`}
                    className="current-drop-image"
                  />
                  <button
                    className="delete-image-button"
                    onClick={() => {
                      handleDeleteImage(index);
                    }}
                  >
                    X
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="image-container">
              {showProofImages.map((image) => (
                <img
                  className="current-drop-image"
                  src={image.ProofImagePath}
                  alt={`drop proof ${id}`}
                />
              ))}
            </div>
          )}

          {/* {images.map((image, index) => (
            <div className="image-container">
              {dropStatus === "InProgress" ? (
                <img
                  key={index}
                  src={`data:image/jpeg;base64,${image}`}
                  alt={`Preview ${dropName}`}
                  className="current-drop-image"
                />
              ) : (
                dropProofArray.map((item, proofIndex) => (
                  <img
                    key={proofIndex}
                    src={
                      isProofAvailable == true
                        ? `data:image/jpeg;base64,${item.ProofImageBlob}`
                        : ""
                    }
                    className="current-drop-image"
                  />
                ))
              )}
              <button
                className="delete-image-button"
                onClick={() => {
                  handleDeleteImage(index);
                }}
              >
                X
              </button>
            </div>
          ))} */}
        </div>
        <div className="add_comment_drop_finish">
          <h3 className="heading-2">Add Comment</h3>
          <textarea
            type="textarea"
            className="add_comment"
            rows="10"
            onChange={(e) => setDropMessage(e.target.value)}
          />
          <div className="complete_drop">
            <div className="complete_drop"> </div>

            <button
              className="button button-1 show__mobile"
              onClick={handleImageChange}
            >
              Take Photo
            </button>
            <label for="image-upload" className="button button-1">
              <IoMdAttach size={20} />
            </label>
            <input
              id="image-upload"
              type="file"
              accept="image/*"
              className="addimages-input"
              onChange={handleImageChange}
            />
            <button
              onClick={() => completeDrop()}
              disabled={!dropCompleteAvailable}
              className="button button-1"
            >
              Complete Drop
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default IndividualDrop;
